// Generated by Framer (2add0ca)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["ROwy8uAQy"];

const variantClassNames = {ROwy8uAQy: "framer-v-n8hi3f"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; title?: string }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "ROwy8uAQy", title: gVe5OvIuy = "Click", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "ROwy8uAQy", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-4lqzg", classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.button {...restProps} className={cx("framer-n8hi3f", className)} data-framer-name={"Variant 1"} data-reset={"button"} layoutDependency={layoutDependency} layoutId={"ROwy8uAQy"} ref={ref} style={{backgroundColor: "rgb(0, 153, 255)", borderBottomLeftRadius: 10, borderBottomRightRadius: 10, borderTopLeftRadius: 10, borderTopRightRadius: 10, ...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.p><motion.span style={{"--font-selector": "R0Y7RE0gU2Fucy01MDA=", "--framer-font-family": "\"DM Sans\", sans-serif", "--framer-font-size": "20px", "--framer-font-weight": "500", "--framer-text-color": "var(--extracted-1w3ko1f)"}}>Click</motion.span></motion.p></React.Fragment>} className={"framer-15a6ug8"} fonts={["GF;DM Sans-500"]} layoutDependency={layoutDependency} layoutId={"WP6uAHquQ"} style={{"--extracted-1w3ko1f": "rgb(255, 255, 255)", "--framer-paragraph-spacing": "0px"}} text={gVe5OvIuy} verticalAlignment={"top"} withExternalLayout/></motion.button></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-4lqzg [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-4lqzg .framer-ajlcve { display: block; }", ".framer-4lqzg .framer-n8hi3f { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 8px; height: min-content; justify-content: center; padding: 10px 20px 10px 20px; position: relative; width: min-content; }", ".framer-4lqzg .framer-15a6ug8 { flex: none; height: auto; overflow: visible; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-4lqzg .framer-n8hi3f { gap: 0px; } .framer-4lqzg .framer-n8hi3f > * { margin: 0px; margin-left: calc(8px / 2); margin-right: calc(8px / 2); } .framer-4lqzg .framer-n8hi3f > :first-child { margin-left: 0px; } .framer-4lqzg .framer-n8hi3f > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 44
 * @framerIntrinsicWidth 86.5
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerVariables {"gVe5OvIuy":"title"}
 */
const FramerSJCsn6OTv: React.ComponentType<Props> = withCSS(Component, css, "framer-4lqzg") as typeof Component;
export default FramerSJCsn6OTv;

FramerSJCsn6OTv.displayName = "Button";

FramerSJCsn6OTv.defaultProps = {height: 44, width: 86.5};

addPropertyControls(FramerSJCsn6OTv, {gVe5OvIuy: {defaultValue: "Click", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(FramerSJCsn6OTv, [{family: "DM Sans", moduleAsset: {localModuleIdentifier: "local-module:canvasComponent/SJCsn6OTv:default", url: "https://fonts.gstatic.com/s/dmsans/v14/rP2tp2ywxg089UriI5-g4vlH9VoD8CmcqZG40F9JadbnoEwAkJxhTmf3ZGMZpg.ttf"}, style: "normal", url: "https://fonts.gstatic.com/s/dmsans/v14/rP2tp2ywxg089UriI5-g4vlH9VoD8CmcqZG40F9JadbnoEwAkJxhTmf3ZGMZpg.ttf", weight: "500"}])